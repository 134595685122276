<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="请输入快递名称关键词" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.company_code" placeholder="平台" filterable clearable>
						<el-option v-for="(company, c) in companies" :key="c" :label="company" :value="c"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="params.regions" :options="$utils.buildTree(regions)" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getExpresses({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create('expresses')">新增快递</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="expresses" :size="theme.size">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.express_fees" size="mini">
							<el-table-column label="地区范围">
								<template slot-scope="scope">
									<el-tag size="mini" type="primary" style="margin-right: 10px;" v-for="region in scope.row.regions" :key="region.id">{{region.name}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="min_price" label="满足金额"></el-table-column>
							<el-table-column prop="min_weight" label="最小重量"></el-table-column>
							<el-table-column prop="max_weight" label="最大重量"></el-table-column>
							<el-table-column prop="init_fee" label="首重费用"></el-table-column>
							<el-table-column prop="add_fee" label="续重费用"></el-table-column>
							<el-table-column prop="updated_at" label="更新时间"></el-table-column>
							<el-table-column prop="created_at" label="创建时间"></el-table-column>
							<el-table-column label="操作">
								<el-button slot="header" type="text" @click="create_fee_drawer = true; express_fee = {express_id: props.row.id};" :disabled="!$utils.create('expresses/fees')">新增</el-button>
								<template slot-scope="scope">
									<el-popconfirm title="确定要恢复这条记录吗？" v-if="scope.row.deleted_at" @confirm="resFee(scope.row)">
										<el-button slot="reference" type="text" :disabled="!$utils.restore('expresses/fees')">恢复</el-button>
									</el-popconfirm>
									<template v-else>
										<el-button type="text" @click="editFee(scope.row)" :disabled="!$utils.update('expresses/fees')">编辑</el-button>
										<el-popconfirm title="确定要恢复这条记录吗？" @confirm="delFee(scope.row)">
											<el-button slot="reference" type="text" :disabled="!$utils.delete('expresses/fees')">删除</el-button>
										</el-popconfirm>
									</template>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="名称">
					<el-input slot-scope="scope" v-model="scope.row.name" placeholder="请输入快递名称" @change="submitExpress(scope.row)" :disabled="scope.row.deleted_at" :size="theme.size" :readonly="!$utils.update('expresses')"></el-input>
				</el-table-column>
				<el-table-column prop="shop.name" label="门店"></el-table-column>
				<el-table-column prop="company_code" label="平台">
					<template slot-scope="scope">{{companies[scope.row.company_code]}}</template>
				</el-table-column>
				<el-table-column prop="description" label="描述说明"></el-table-column>
				<el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
				<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
				<el-table-column prop="name" label="更多操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('expresses')" v-if="scope.row.deleted_at">恢复</el-button>
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('expresses')" v-else>删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getExpresses({ ...params, page: 1, perPage: v}) }"
				@current-change="(v) => { getExpresses({ ...params, page: v}) }"
				background>	
			</el-pagination>
		</main-table>
		<el-drawer title="新增快递" :visible.sync="create_drawer">
			<div style="padding: 0 15px;">
				<el-form ref="express_form" label-width="80px" :model="express" :size="theme.size">
					<el-form-item prop="name" label="名称" :rules="[{ required: true, message: '必须填写快递名称'}]">
						<el-input v-model="express.name" placeholder="请输入快递名称"></el-input>
					</el-form-item>
					<el-form-item prop="company_code" label="物流平台" :rules="[{ required: true, message: '必须选择物流平台'}]">
						<el-select v-model="express.company_code" placeholder="请选择物流平台" filterable>
							<el-option v-for="(company, c) in companies" :key="c" :label="company" :value="c"></el-option>         
						</el-select>
					</el-form-item>
					<el-form-item prop="description" label="描述说明">
						<el-input v-model="express.description" placeholder="请输入快递描述说明"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('express_form')">立即创建</el-button>
						<el-button>取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
		<el-drawer title="新增费用" :visible.sync="create_fee_drawer">
			<div style="padding: 0 15px;">
				<el-form ref="express_fee_form" label-width="80px" :rules="create_fee_rules" :model="express_fee" :size="theme.size">
					<el-form-item prop="regions" label="地区范围">
						<el-select style="width: 100%;" v-model="express_fee.regions" placeholder="请选择" multiple>
							<el-option v-for="region in regions" :key="region.id" :label="region.name" :value="region.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item prop="min_price" label="满足金额">
						<el-input v-model.number="express_fee.min_price" placeholder="请输入满足金额">
							<template slot="append">分</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="min_weight" label="最小重量">
						<el-input v-model.number="express_fee.min_weight" placeholder="请输入最小重量">
							<template slot="append">克</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="max_weight" label="最大重量">
						<el-input v-model.number="express_fee.max_weight" placeholder="请输入最大重量">
							<template slot="append">克</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="init_fee" label="首重费用">
						<el-input v-model.number="express_fee.init_fee" placeholder="请输入首重费用">
							<template slot="append">分</template>
						</el-input>
					</el-form-item>
					<el-form-item prop="add_fee" label="续重费用">
						<el-input v-model.number="express_fee.add_fee" placeholder="请输入续重费用">
							<template slot="append">分</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('express_fee_form')">立即创建</el-button>
						<el-button>取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';

	export default {
		components: {
			mainTable
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			editFee (r) {
				const fee = { ...r };
				fee.regions = fee.regions.map((i) => {
					return i.id;
				});
				this.express_fee = fee;
				this.create_fee_drawer = true;
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】快递吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_EXPRESSES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getExpresses(this.params);
						}
					});
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】快递吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_EXPRESSES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getExpresses(this.params);
						}
					});
				})
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'express_form':
							this.submitExpress(this.express);
						break;
						case 'express_fee_form':
							this.submitExpressFees(this.express_fee);
						break;
					}
				})
			},
			async submitExpress (data) {
				const res = await this.$http.post(this.$api.URI_EXPRESSES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getExpresses(this.params);
						}
					}
				})
			},
			async submitExpressFees (data) {
				const res = await this.$http.post(this.$api.URI_EXPRESSES_FEES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_fee_drawer) {
							this.create_fee_drawer = false;
							this.getExpresses(this.params);
						}
					}
				})
			},
			async getExpresses (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EXPRESSES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.expresses = result.data;
				this.shops = result.shops;
				this.companies = result.expresses;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			var validateMinWeight = (rule, value, callback) => {
				const { max_weight } = this.express_fee;
				if (value === '') {
					callback(new Error('必须填写最小重量'));
				} else if (value > max_weight) {
					callback(new Error('最小重量不能大于最大重量'));
				} else {
					callback();
				}
			};
			var validateMaxWeight = (rule, value, callback) => {
				const { min_weight } = this.express_fee;
				if (value === '') {
					callback(new Error('必须填写最大重量'));
				} else if (value < min_weight) {
					callback(new Error('最大重量不能小于最小重量'));
				} else {
					callback();
				}
			};
			return {
				shops: [],
				regions: [],
				expresses: [],
				companies: [],
				create_drawer: false,
				create_fee_drawer: false,
				express: {},
				express_fee: {},
				create_fee_rules: {
					regions: [{ required: true, message: '选择区域范围'}],
					min_price: [{ required: true, message: '必须填写满足金额'}],
					min_weight: [
						{ required: true, message: '必须填写最小重量' },
						{ validator: validateMinWeight, trigger: 'blur' }
					],
					max_weight: [
						{ required: true, message: '必须填写最大重量' },
						{ validator: validateMaxWeight, trigger: 'blur' }
					],
					init_fee: [{ required: true, message: '必须填写首重费用'}],
					add_fee: [{ required: true, message: '必须填写续重费用'}]
				},
				params: {
					perPage: 10,
				}
			}
		},
		async created () {
			this.getExpresses(this.params, true);
			const res = await this.$http.get(this.$api.URI_REGIONS, {params: {action: 'regions'}, headers: {loading: true}});
			const { code, result } = res.data;
			if (code == 0) {
				this.regions = result;
			}
		}
	};
</script>